import React from "react";

export const Facebook = ({ onClick }) => {
  return (
    <div className="facebook-icon-svg">
      <svg
        aria-hidden="true"
        role="img"
        viewBox="0 0 100 100"
        onClick={onClick}
      >
        <path
          fill="currentColor"
          d="M 75.048829,56.450001 77.826172,38.352344 H 60.460938 V 26.608203 c 0,-4.951171 2.425781,-9.777344 10.203125,-9.777344 h 7.894531 V 1.4226562 C 78.558594,1.4226562 71.394532,0.2 64.544922,0.2 50.244141,0.2 40.896485,8.8679688 40.896485,24.559375 V 38.352344 H 25 V 56.450001 H 40.896485 V 100.2 H 60.460938 V 56.450001 Z"
          class=""
        ></path>
      </svg>
    </div>
  );
};

export const Youtube = ({ onClick }) => {
  return (
    <div className="youtube-icon-svg">
      <svg
        aria-hidden="true"
        role="img"
        viewBox="0 0 100 100"
        onClick={onClick}
      >
        <path
          fill="currentColor"
          d="m 98.090971,25.599329 c -1.149855,-4.32958 -4.537728,-7.739421 -8.839299,-8.8966 C 81.454768,14.6 50.190077,14.6 50.190077,14.6 c 0,0 -31.264508,0 -39.061595,2.10273 C 6.8269115,17.860091 3.4390382,21.269749 2.2891825,25.599329 0.2,33.446944 0.2,49.820261 0.2,49.820261 c 0,0 0,16.373316 2.0891825,24.220931 1.1498557,4.32958 4.537729,7.59736 8.8392995,8.754538 7.797087,2.10273 39.061595,2.10273 39.061595,2.10273 0,0 31.264508,0 39.061595,-2.10273 4.301571,-1.157178 7.689444,-4.424958 8.839299,-8.754538 2.089179,-7.847615 2.089179,-24.220931 2.089179,-24.220931 0,0 0,-16.373317 -2.089179,-24.220932 z M 39.964763,64.686005 V 34.954516 l 26.131073,14.866111 z"
          style={{ "stroke-width": "0.183069" }}
        />
      </svg>
    </div>
  );
};

export const Close = ({ onClick }) => {
  return (
    <div className="close-icon-svg">
      <svg
        aria-hidden="true"
        role="img"
        viewBox="0 0 100 100"
        onClick={onClick}
      >
        <path
          fill="currentColor"
          d="m 68.954548,49.999999 28.42898,-28.42898 c 3.488632,-3.488629 3.488632,-9.144879 0,-12.6363592 l -6.31819,-6.3181799 c -3.48863,-3.48863988 -9.14488,-3.48863988 -12.63635,0 L 49.999999,31.045459 21.571019,2.6164799 c -3.488629,-3.48863988 -9.144889,-3.48863988 -12.6363592,0 L 2.6164799,8.9346598 c -3.48863988,3.4886402 -3.48863988,9.1448802 0,12.6363592 L 31.045459,49.999999 2.6164799,78.428978 c -3.48863988,3.48863 -3.48863988,9.14489 0,12.63636 l 6.3181799,6.31818 c 3.4886402,3.488642 9.1477302,3.488642 12.6363592,0 l 28.42898,-28.42897 28.428989,28.42897 c 3.48863,3.488642 9.14772,3.488642 12.63635,0 l 6.31819,-6.31818 c 3.488632,-3.48863 3.488632,-9.14488 0,-12.63636 z"
          style={{ "stroke-width": "0.28409" }}
        />
      </svg>
    </div>
  );
};
